{
    "header": {
        "documentation": "Dokumentation",
        "translate": "Übersetzungen hinzufügen"
    },
    "footer": {
        "documentation": "Dokumentation"
    },
    "page": {
        "connection": {
            "title": "FluidNC Web Installer",
            "description": "Dieses Tool vereinfacht die Installation oder Aktualisierung von FluidNC auf Ihrem Controller. Schließen Sie Ihren Controller an und drücken Sie die Verbinden-Taste, um fortzufahren.",
            "connect": "Verbinden",
            "connecting": "Verbindung wird hergestellt",
            "establishing-connection": "Verbindung zum Controller wird hergestellt"
        },
        "home": {
            "description": "Sie sind jetzt mit einem Gerät verbunden. Bitte wählen Sie unten eine Aktion aus",
            "error-while-booting": "Es gab einen Fehler während des Bootvorgangs, wahrscheinlich ist die Konfiguration fehlerhaft.",
            "show-details": "Hier klicken, um Details zu sehen"
        },
        "file-browser": {
            "title": "Dateibrowser",
            "loading": "Lade...",
            "downloading": "Download läuft...",
            "uploading": "Lädt hoch",
            "config-missing": "Die Konfigurationsdatei \"{{configFilename}}\" fehlt, wollen Sie sie erstellen?",
            "create-config": "Konfiguration erstellen",
            "create-new-config": "Neue Konfiguration erstellen",
            "upload": "Hochladen",
            "active-config": "Aktive Konfiguration",
            "select-config": "Konfiguration auswählen",
            "select-as-config": "Als Konfiguration auswählen",
            "edit": "Bearbeiten",
            "delete": "Löschen",
            "download": "Herunterladen"
        },
        "terminal": {
            "title": "Konsole",
            "restarting": "Controller wird neu gestartet...",
            "restart": "Neustart",
            "restart-description": "Führt einen harten Reset des Controllers durch DTR/RTS durch",
            "reset": "Zurücksetzen",
            "reset-description": "Setzt den Controller mit 0x18-Befehl zurück",
            "unlock": "Entsperren",
            "unlock-description": "Entsperrt den Controller mit $X",
            "status": "Status",
            "status-description": "Controller-Status abrufen",
            "startup": "Startnachrichten",
            "startup-description": "Start-Log-Nachrichten abrufen",
            "version": "Version",
            "version-description": "Firmware-Version abrufen"
        }
    },
    "panel": {
        "navigation": {
            "home": "Startseite",
            "install": "Installieren",
            "terminal": "Konsole",
            "file-browser": "Dateibrowser",
            "wifi": "WLAN",
            "calibrate": "Einstellungen",
            "restart": "Neustart",
            "disconnect": "Verbindung trennen"
        },
        "firmware": {
            "install": "Installation",
            "install-description": "Wählen Sie aus, welche Version der Firmware Sie auf Ihrem Controller installieren möchten.",
            "loading": "lade...",
            "install-custom-image": "Benutzerdefiniertes Image installieren...",
            "show-prereleases": "Vorab-Versionen anzeigen",
            "fetching": "lade"
        },
        "progress": {
            "title": "Installiere",
            "downloading": "Paket wird heruntergeladen...",
            "validating": "Images werden überprüft...",
            "flashing": "Paket wird auf Gerät installiert..."
        },
        "bootloader": {
            "waiting": "Warte darauf, dass der Controller auf den Bootloader zugreifen kann...",
            "not-active": "Bootloader nicht aktiv - Versuchen Sie, die BOOT-Taste gedrückt zu halten"
        },
        "unsupported": {
            "title": "Browser not supported",
            "description": "Your current web browser is not supported, please use Chrome, Edge or Opera instead"
        }
    },
    "component": {
        "log": {
            "show-details": "Details anzeigen",
            "hide-details": "Details verstecken"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "Beim Neuverbinden ist ein Fehler aufgetreten, bitte starten Sie den Controller neu",
            "uploading": "Lädt hoch",
            "downloading": "Download läuft",
            "downloading-description": "Paket wird heruntergeladen...",
            "restarting": "Starte neu",
            "restarting-description": "Warte auf Neustart des Controllers...",
            "uploading-files": "Dateien werden hochgeladen",
            "uploading-files-description": "Dateien werden hochgeladen...",
            "done": "Fertig",
            "done-description": "Die Installation war erfolgreich. Der Controller kann jetzt genutzt werden.",
            "continue": "Weiter",
            "error": "Fehler!",
            "close": "Schließen",
            "confirm-installation": "Installation bestätigen",
            "confirm-installation-description": "Die Firmware-Version \"{{release}}\" wird auf dem Controller installiert. Der Controller wird {{not}} gelöscht werden.",
            "not": "nicht",
            "installation-speed": "Installationsgeschwindigkeit",
            "installation-speed-help": "Einige Controller müssen mit niedrigerer Geschwindigkeit installiert werden. Bei Problemen können Sie es mit einer geringeren Geschwindigkeit versuchen.",
            "install": "Installieren",
            "cancel": "Abbrechen",
            "select-image": "Image wählen",
            "select-image-description1": "Bei manuellem Download können Sie das Image hier flashen.",
            "select-image-description2": "Wählen Sie die Firmware.bin zum flashen des Controllers.",
            "select-image-button": "Firmware-Image auswählen"
        },
        "lost-connection": {
            "description": "Lost the connection to the controller",
            "close": "Close"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Upgrade von FluidNC auf deinem Controller",
            "install-description": "FluidNC scheint nicht installiert zu sein, möchtest du es installieren?",
            "upgrade-button": "FluidNC upgraden",
            "install-button": "FluidNC installieren"
        },
        "terminal": {
            "open-terminal": "Konsole öffnen",
            "open-terminal-description": "Mittels Konsole zu deinem Controller verbinden"
        },
        "file-browser": {
            "open-browser": "Dateibrowser",
            "open-browser-description": "Dateien auf dem Controller verwalten"
        },
        "calibrate": {
            "calibrate": "Einstellen",
            "calibrate-description": "Einstellungen auf Ihrem Controller konfigurieren"
        },
        "wifi": {
            "configure": "WLAN konfigurieren",
            "configure-description": "WLAN-Einstellungen des Controllers konfigurieren",
            "connected-to": "Verbunden mit",
            "access-point": "Access-Point"
        },
        "donate": {
            "description": "Please consider a donation to the FluidNC project via the links below to keep it going."
        }
    }
}
