{
    "header": {
        "documentation": "Documentos",
        "translate": "Agregar traducción"
    },
    "footer": {
        "documentation": "Documentos"
    },
    "page": {
        "connection": {
            "title": "FluidNC Instalador Web",
            "description": "Esta herramienta facilitará la instalación o actualización de FluidNC en su controlador. Conecte el controlador y pulse \"Conectar\" para continuar.",
            "connect": "Conectar",
            "connecting": "Conectando",
            "establishing-connection": "Estableciendo conexión con el controlador"
        },
        "home": {
            "description": "Ahora está conectado a un dispositivo, elija una acción a continuación",
            "error-while-booting": "Se produjo un error durante el arranque, probablemente debido a una configuración no válida.",
            "show-details": "Haga clic aquí para ver los detalles"
        },
        "file-browser": {
            "title": "Explorador de archivos",
            "loading": "Cargando...",
            "downloading": "Descargando...",
            "uploading": "Subiendo",
            "config-missing": "Falta el archivo de configuración \"{{configFilename}}\", ¿desea crearlo?",
            "create-config": "Crear configuración",
            "create-new-config": "Crear nueva configuración",
            "upload": "Subir",
            "active-config": "Configuración activa",
            "select-config": "Seleccione configuración",
            "select-as-config": "Seleccionar como configuración",
            "edit": "Editar",
            "delete": "Borrar",
            "download": "Descargar"
        },
        "terminal": {
            "title": "Terminal",
            "restarting": "Reiniciando el controlador...",
            "restart": "Reanudar",
            "restart-description": "¿Se realiza un reinicio completo del controlador configurando DTR/RTS?",
            "reset": "Reset",
            "reset-description": "Reinicia el controlador con un comando 0x18",
            "unlock": "Desbloquear",
            "unlock-description": "Desbloquea el controlador con $X",
            "status": "Estado",
            "status-description": "Obtener el estado del controlador",
            "startup": "Mensajes de inicio",
            "startup-description": "Obtener mensajes de registro de inicio",
            "version": "Version",
            "version-description": "Obtener la versión del firmware"
        }
    },
    "panel": {
        "navigation": {
            "home": "Home",
            "install": "Instalar",
            "terminal": "Terminal",
            "file-browser": "Explorador de archivos",
            "wifi": "WiFi",
            "calibrate": "Calibrar",
            "restart": "Reiniciar",
            "disconnect": "Desconectar"
        },
        "firmware": {
            "install": "Instalar",
            "install-description": "Seleccione el firmware que desea instalar en su controlador.",
            "loading": "Cargando...",
            "install-custom-image": "Instalar una imagen personalizada...",
            "show-prereleases": "Mostrar prelanzamientos",
            "fetching": "Obteniendo"
        },
        "progress": {
            "title": "Instalando",
            "downloading": "Descargando paquete...",
            "validating": "Validando imágenes...",
            "flashing": "Instalando paquete en el dispositivo..."
        },
        "bootloader": {
            "waiting": "Esperando que el controlador ingrese al cargador de arranque...",
            "not-active": "Cargador de arranque no activo: intenta mantener presionado el interruptor BOOT"
        },
        "unsupported": {
            "title": "Navegador no compatible",
            "description": "Su navegador web actual no es compatible, utilice Chrome, Edge u Opera en su lugar"
        }
    },
    "component": {
        "log": {
            "show-details": "Mostrar detalles",
            "hide-details": "Ocultar detalles"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "Se produjo un error al reconectarse, reinicie el controlador.",
            "uploading": "Subiendo",
            "downloading": "Descargando",
            "downloading-description": "Descargando paquete...",
            "restarting": "Reiniciando",
            "restarting-description": "Esperando que se reinicie el controlador...",
            "uploading-files": "Subiendo archivos",
            "uploading-files-description": "Subiendo archivos...",
            "done": "Hecho",
            "done-description": "El controlador se ha instalado correctamente y está listo para usarse.",
            "continue": "Continuar",
            "error": "¡Error!",
            "close": "Cerrar",
            "confirm-installation": "Confirme instalación",
            "confirm-installation-description": "Esto instalará el firmware \"{{release}}\" en el controlador. No lo borrará.",
            "not": "no",
            "installation-speed": "Velocidad de instalación",
            "installation-speed-help": "Algunos controladores deben instalarse a una velocidad más baja. Si tiene problemas, puede intentar reducir la velocidad.",
            "install": "Instalar",
            "cancel": "Cancelar",
            "select-image": "Seleccione imagen",
            "select-image-description1": "Si has descargado un paquete manualmente puedes flashear la imagen desde aquí.",
            "select-image-description2": "Seleccione el archivo firmware .bin para flashear el controlador.",
            "select-image-button": "Seleccionar imagen de firmware"
        },
        "lost-connection": {
            "description": "Se perdió la conexión con el controlador",
            "close": "Cerrar"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Actualice FluidNC en su controlador",
            "install-description": "Parece que el controlador no tiene instalado FluidNC, ¿desea instalarlo?",
            "upgrade-button": "Actualice FluidNC",
            "install-button": "Instale FluidNC"
        },
        "terminal": {
            "open-terminal": "Abrir terminal",
            "open-terminal-description": "Abra una terminal serial a su controlador"
        },
        "file-browser": {
            "open-browser": "Explorador de archivos",
            "open-browser-description": "Administrar archivos en el controlador"
        },
        "calibrate": {
            "calibrate": "Calibrar",
            "calibrate-description": "Calibrar la configuración de su controlador"
        },
        "wifi": {
            "configure": "Configurar Wi-Fi",
            "configure-description": "Administrar la configuración Wi-Fi del controlador",
            "connected-to": "Conectado a",
            "access-point": "Punto de acceso"
        },
        "donate": {
            "description": "Considere hacer una donación al proyecto FluidNC a través de los enlaces a continuación para sostener el proyecto."
        }
    }
}
