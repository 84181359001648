{
    "header": {
        "documentation": "Documentation",
        "translate": "Ajouter des traductions"
    },
    "footer": {
        "documentation": "Documentation"
    },
    "page": {
        "connection": {
            "title": "Installateur Web FluidNC",
            "description": "Cet outil facilitera l'installation ou la mise à niveau de FluidNC sur votre contrôleur. Branchez votre contrôleur et appuyez sur Connecter pour continuer.",
            "connect": "Connecter",
            "connecting": "Connexion en cours",
            "establishing-connection": "Établissement de la connexion au contrôleur"
        },
        "home": {
            "description": "Vous êtes maintenant connecté à un appareil, veuillez choisir une action ci-dessous",
            "error-while-booting": "Il y a eu une erreur au démarrage, probablement en raison d'une configuration non valide.",
            "show-details": "Cliquez ici pour voir les détails"
        },
        "file-browser": {
            "title": "Explorateur de fichiers",
            "loading": "Chargement en cours...",
            "downloading": "Téléchargement en cours...",
            "uploading": "Téléchargement en cours",
            "config-missing": "Le fichier de configuration \"{{configFilename}}\" est manquant, voulez-vous le créer ?",
            "create-config": "Créer une configuration",
            "create-new-config": "Créer une nouvelle configuration",
            "upload": "Télécharger",
            "active-config": "Configuration active",
            "select-config": "Sélectionner une configuration",
            "select-as-config": "Sélectionner comme configuration",
            "edit": "Modifier",
            "delete": "Supprimer",
            "download": "Télécharger"
        },
        "terminal": {
            "title": "Terminal",
            "restarting": "Redémarrage du contrôleur...",
            "restart": "Redémarrer",
            "restart-description": "Effectue une réinitialisation dure du contrôleur en définissant DTR/RTS",
            "reset": "Réinitialiser",
            "reset-description": "Réinitialise le contrôleur avec une commande 0x18",
            "unlock": "Déverrouiller",
            "unlock-description": "Déverrouille le contrôleur avec $X",
            "status": "État",
            "status-description": "Obtenir l'état du contrôleur",
            "startup": "Messages de démarrage",
            "startup-description": "Obtenir les messages du journal de démarrage",
            "version": "Version",
            "version-description": "Obtenir la version du micrologiciel"
        }
    },
    "panel": {
        "navigation": {
            "home": "Page d’accueil",
            "install": "Installer",
            "terminal": "Terminal",
            "file-browser": "Navigateur de fichiers",
            "wifi": "Wi-Fi",
            "calibrate": "Étalonner",
            "restart": "Redémarrer",
            "disconnect": "Déconnecter"
        },
        "firmware": {
            "install": "Installer",
            "install-description": "Sélectionnez le firmware que vous souhaitez installer sur votre contrôleur.",
            "loading": "Chargement...",
            "install-custom-image": "Installer une image personnalisée...",
            "show-prereleases": "Afficher les préversions",
            "fetching": "Récupération"
        },
        "progress": {
            "title": "Installation en cours...",
            "downloading": "Téléchargement du progiciel en cours",
            "validating": "Validation des images...",
            "flashing": "Installation du progiciel sur l'appareil..."
        },
        "bootloader": {
            "waiting": "En attente que le contrôleur entre dans le chargeur de démarrage...",
            "not-active": "Bootloader n'est pas actif - Essayez de maintenir le bouton BOOT enfoncé"
        },
        "unsupported": {
            "title": "Le navigateur n'est pas pris en charge.",
            "description": "Votre navigateur web actuel n'est pas pris en charge, veuillez utiliser Chrome, Edge ou Opéra à la place"
        }
    },
    "component": {
        "log": {
            "show-details": "Afficher les détails",
            "hide-details": "Masquer les détails"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "Une erreur s'est produite lors de la reconnexion, veuillez redémarrer le contrôleur",
            "uploading": "Téléchargement en cours",
            "downloading": "Téléchargement en cours",
            "downloading-description": "Téléchargement du progiciel...",
            "restarting": "Redémarrage en cours",
            "restarting-description": "En attente du redémarrage du contrôleur...",
            "uploading-files": "Téléchargement du fichier en cours",
            "uploading-files-description": "Téléchargement des fichiers en cours",
            "done": "Fait",
            "done-description": "Le contrôleur a été installé avec succès et est prêt à être utilisé.",
            "continue": "Continuer",
            "error": "Erreur !",
            "close": "Fermer",
            "confirm-installation": "Confirmer l'installation",
            "confirm-installation-description": "Cela installera le firmware « {{release}} » sur le contrôleur. Cela n'effacera pas le contrôleur.",
            "not": "non",
            "installation-speed": "Vitesse d'installation",
            "installation-speed-help": "Certains contrôleurs doivent être installés à une vitesse inférieure. Si vous rencontrez des problèmes, vous pouvez essayer de diminuer la vitesse.",
            "install": "Installer",
            "cancel": "Annuler",
            "select-image": "Sélectionner une image",
            "select-image-description1": "Si vous avez téléchargé un micrologiciel manuellement, vous pouvez flasher l'image du micrologiciel à partir d'ici.",
            "select-image-description2": "Sélectionnez le fichier firmware.bin avec lequel le contrôleur sera flashé.",
            "select-image-button": "Sélectionnez l'image du micrologiciel"
        },
        "lost-connection": {
            "description": "Lost the connection to the controller",
            "close": "Close"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Mettez à niveau FluidNC sur votre contrôleur",
            "install-description": "Le contrôleur ne semble pas avoir installé FluidNC, voulez-vous l'installer ?",
            "upgrade-button": "Mise à niveau de FluidNC",
            "install-button": "Installer FluidNC"
        },
        "terminal": {
            "open-terminal": "Ouvrir le terminal",
            "open-terminal-description": "Ouvrir un terminal série sur votre contrôleur"
        },
        "file-browser": {
            "open-browser": "Navigateur de fichiers",
            "open-browser-description": "Gérer les fichiers sur le contrôleur"
        },
        "calibrate": {
            "calibrate": "Étalonner",
            "calibrate-description": "Calibrer les paramètres de votre contrôleur"
        },
        "wifi": {
            "configure": "Configurer le WiFi",
            "configure-description": "Gérer les paramètres WiFi du contrôleur",
            "connected-to": "Connecté à",
            "access-point": "Point d’Accès wifi"
        },
        "donate": {
            "description": "Please consider a donation to the FluidNC project via the links below to keep it going."
        }
    }
}
