{
    "header": {
        "documentation": "Документація",
        "translate": "Додати переклади"
    },
    "footer": {
        "documentation": "Документація"
    },
    "page": {
        "connection": {
            "title": "Вебвстановлювач FluidNC",
            "description": "Цей інструмент спрощує встановлення або оновлення FluidNC на ваш контролер. Під’єднайте контролер і натисніть «Підключити», щоб продовжити.",
            "connect": "Підключити",
            "connecting": "Підключення",
            "establishing-connection": "Встановлення з’єднання з контролером"
        },
        "home": {
            "description": "Ви підключені до пристрою, будь ласка, виберіть одну з дій нижче",
            "error-while-booting": "Під час запуску сталася помилка, ймовірно через некоректну конфігурацію.",
            "show-details": "Клацніть тут, щоб переглянути деталі"
        },
        "file-browser": {
            "title": "Оглядач файлів",
            "loading": "Завантаження...",
            "downloading": "Завантаження...",
            "uploading": "Вивантаження",
            "config-missing": "Файл конфігурації {{configFilename}} відсутній. Бажаєте створити його?",
            "create-config": "Створити файл конфігурації",
            "create-new-config": "Створити новий конфіг",
            "upload": "Вивантажити",
            "active-config": "Активний конфіг",
            "select-config": "Вибрати конфіг",
            "select-as-config": "Обрати як конфіг",
            "edit": "Редагувати",
            "delete": "Видалити",
            "download": "Завантажити"
        },
        "terminal": {
            "title": "Термінал",
            "restarting": "Перезапуск контролера...",
            "restart": "Перезапуск",
            "restart-description": "Виконує жорстке скидання контролера шляхом встановлення DTR/RTS",
            "reset": "Скидання",
            "reset-description": "Скидає контролер за допомогою команди 0x18",
            "unlock": "Розблокувати",
            "unlock-description": "Розблоковує контролер за допомогою команди $X",
            "status": "Статус",
            "status-description": "Отримати стан контролера",
            "startup": "Повідомлення під час запуску",
            "startup-description": "Отримати журнал стартових повідомлень",
            "version": "Версія",
            "version-description": "Отримати версію прошивки"
        }
    },
    "panel": {
        "navigation": {
            "home": "Головна",
            "install": "Встановити",
            "terminal": "Термінал",
            "file-browser": "Оглядач файлів",
            "wifi": "Wi-Fi",
            "calibrate": "Калібрування",
            "restart": "Перезапуск",
            "disconnect": "Відключити"
        },
        "firmware": {
            "install": "Встановити",
            "install-description": "Виберіть прошивку, яку бажаєте встановити на свій контролер.",
            "loading": "Завантаження...",
            "install-custom-image": "Встановити власний образ...",
            "show-prereleases": "Показати попередні релізи",
            "fetching": "Отримання"
        },
        "progress": {
            "title": "Встановлення",
            "downloading": "Завантаження пакета...",
            "validating": "Перевірка образів...",
            "flashing": "Встановлення пакета на пристрій..."
        },
        "bootloader": {
            "waiting": "Очікування входу контролера в завантажувач...",
            "not-active": "Завантажувач не активний — спробуйте утримувати кнопку BOOT"
        },
        "unsupported": {
            "title": "Цей браузер не підтримується",
            "description": "Ваш браузер не підтримується. Рекомендуємо використовувати Chrome, Edge або Opera"
        }
    },
    "component": {
        "log": {
            "show-details": "Докладніше",
            "hide-details": "Згорнути"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "Під час повторного підключення сталася помилка. Будь ласка, перезапустіть контролер",
            "uploading": "Вивантаження",
            "downloading": "Завантаження",
            "downloading-description": "Завантаження пакета...",
            "restarting": "Перезапуск",
            "restarting-description": "Очікування перезапуску контролера...",
            "uploading-files": "Вивантаження файлів",
            "uploading-files-description": "Вивантаження файлів...",
            "done": "Готово",
            "done-description": "Контролер успішно встановлено й він готовий до використання.",
            "continue": "Продовжити",
            "error": "Помилка!",
            "close": "Закрити",
            "confirm-installation": "Підтвердити встановлення",
            "confirm-installation-description": "Це встановить прошивку \"{{release}}\" на контролер. Це {{not}} призведе до стирання даних контролера.",
            "not": "не",
            "installation-speed": "Швидкість встановлення",
            "installation-speed-help": "Деякі контролери потребують встановлення на нижчій швидкості. Якщо у вас виникають проблеми, спробуйте зменшити швидкість.",
            "install": "Встановити",
            "cancel": "Скасувати",
            "select-image": "Виберіть образ",
            "select-image-description1": "Якщо ви вручну завантажили пакет, можете прошити образ звідси.",
            "select-image-description2": "Виберіть файл firmware.bin, щоб прошити контролер.",
            "select-image-button": "Виберіть образ прошивки"
        },
        "lost-connection": {
            "description": "Втрачено з’єднання з контролером",
            "close": "Закрити"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Оновити FluidNC на вашому контролері",
            "install-description": "Схоже, що на контролері не встановлено FluidNC. Бажаєте встановити його?",
            "upgrade-button": "Оновити FluidNC",
            "install-button": "Встановити FluidNC"
        },
        "terminal": {
            "open-terminal": "Відкрити Термінал",
            "open-terminal-description": "Відкрити серійний термінал до контролера"
        },
        "file-browser": {
            "open-browser": "Оглядач файлів",
            "open-browser-description": "Керувати файлами на контролері"
        },
        "calibrate": {
            "calibrate": "Калібрувати",
            "calibrate-description": "Калібрувати налаштування на вашому контролері"
        },
        "wifi": {
            "configure": "Налаштувати Wi-Fi",
            "configure-description": "Керування налаштуваннями Wi-Fi контролера",
            "connected-to": "Підключено до",
            "access-point": "Точка доступу"
        },
        "donate": {
            "description": "Будь ласка, розгляньте можливість зробити пожертву на підтримку проєкту FluidNC за посиланнями нижче, щоб він продовжував розвиватися."
        }
    }
}
