{
    "header": {
        "documentation": "Documentation",
        "translate": "Add translations"
    },
    "footer": {
        "documentation": "Documentation"
    },
    "page": {
        "connection": {
            "title": "FluidNC Web Installer",
            "description": "This tool will make it easy to install or upgrade FluidNC on your controller. Plug in your controller and press Connect to continue.",
            "connect": "Connect",
            "connecting": "Connecting",
            "establishing-connection": "Establishing connection to controller"
        },
        "home": {
            "description": "You are now connected to a device, please choose an action below",
            "error-while-booting": "There was an error during boot, likely due to an invalid configuration.",
            "show-details": "Click here to see details"
        },
        "file-browser": {
            "title": "File browser",
            "loading": "Loading...",
            "downloading": "Downloading...",
            "uploading": "Uploading",
            "config-missing": "The configuration file \"{{configFilename}}\" is missing, do you want to create it?",
            "create-config": "Create config",
            "create-new-config": "Create new config",
            "upload": "Upload",
            "active-config": "Active config",
            "select-config": "Select config",
            "select-as-config": "Select as config",
            "edit": "Edit",
            "delete": "Delete",
            "download": "Download"
        },
        "terminal": {
            "title": "Terminal",
            "restarting": "Restarting controller...",
            "restart": "Restart",
            "restart-description": "Does a hard reset on the controller by setting DTR/RTS",
            "reset": "Reset",
            "reset-description": "Resets the controller with a 0x18 command",
            "unlock": "Unlock",
            "unlock-description": "Unlocks the controller with $X",
            "status": "Status",
            "status-description": "Get the controller status",
            "startup": "Startup messages",
            "startup-description": "Get startup log messages",
            "version": "Version",
            "version-description": "Get the firmware version"
        }
    },
    "panel": {
        "navigation": {
            "home": "Home",
            "install": "Install",
            "terminal": "Terminal",
            "file-browser": "File browser",
            "wifi": "WiFi",
            "calibrate": "Calibrate",
            "restart": "Restart",
            "disconnect": "Disconnect"
        },
        "firmware": {
            "install": "Install",
            "install-description": "Select which firmware you want to install on your controller.",
            "loading": "Loading...",
            "install-custom-image": "Install a custom image...",
            "show-prereleases": "Show pre-releases",
            "fetching": "Fetching"
        },
        "progress": {
            "title": "Installing",
            "downloading": "Downloading package...",
            "validating": "Validating images...",
            "flashing": "Installing package to device..."
        },
        "bootloader": {
            "waiting": "Waiting for controller to enter bootloader...",
            "not-active": "Bootloader not active - Try holding down the BOOT switch"
        },
        "unsupported": {
            "title": "Browser not supported",
            "description": "Your current web browser is not supported, please use Chrome, Edge or Opera instead"
        }
    },
    "component": {
        "log": {
            "show-details": "Show details",
            "hide-details": "Hide details"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "An error occured while reconnecting, please reboot the controller",
            "uploading": "Uploading",
            "downloading": "Downloading",
            "downloading-description": "Downloading package...",
            "restarting": "Restarting",
            "restarting-description": "Waiting for controller restart...",
            "uploading-files": "Uploading files",
            "uploading-files-description": "Uploading files...",
            "done": "Done",
            "done-description": "The controller has been successfully installed and is ready to be used.",
            "continue": "Continue",
            "error": "Error!",
            "close": "Close",
            "confirm-installation": "Confirm installation",
            "confirm-installation-description": "This will install the firmware \"{{release}}\" to the controller. It will {{not}} erase the controller.",
            "not": "not",
            "installation-speed": "Installation speed",
            "installation-speed-help": "Some controllers need to be installed at a lower speed. If you are experiencing problems you can try and decrease the speed.",
            "install": "Install",
            "cancel": "Cancel",
            "select-image": "Select image",
            "select-image-description1": "If you have downloaded a package manually you can flash the image from here.",
            "select-image-description2": "Select the firmware.bin file to flash the controller with.",
            "select-image-button": "Select firmware image"
        },
        "lost-connection": {
            "description": "Lost the connection to the controller",
            "close": "Close"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Upgrade FluidNC on your controller",
            "install-description": "The controller does not seem to have FluidNC installed, do you wish to install it?",
            "upgrade-button": "Upgrade FluidNC",
            "install-button": "Install FluidNC"
        },
        "terminal": {
            "open-terminal": "Open terminal",
            "open-terminal-description": "Open a serial terminal to your controller"
        },
        "file-browser": {
            "open-browser": "File browser",
            "open-browser-description": "Manage files on the controller"
        },
        "calibrate": {
            "calibrate": "Calibrate",
            "calibrate-description": "Calibrate settings on your controller"
        },
        "wifi": {
            "configure": "Configure WiFi",
            "configure-description": "Manage the controller WiFi settings",
            "connected-to": "Connected to",
            "access-point": "Access Point"
        },
        "donate": {
            "description": "Please consider a donation to the FluidNC project via the links below to keep it going."
        }
    }
}
