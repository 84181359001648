{
    "header": {
        "documentation": "Dokumentation",
        "translate": "Översättningar"
    },
    "footer": {
        "documentation": "Dokumentation"
    },
    "page": {
        "connection": {
            "title": "FluidNC webb-installerare",
            "description": "Detta verktyg gör det enkelt att installera eller uppgradera FluidNC på din enhet. Koppla in din enhet och klicka på \"Anslut\" för att fortsätta.",
            "connect": "Anslut",
            "connecting": "Ansluter",
            "establishing-connection": "Etablerar en anslutning till din enhet"
        },
        "home": {
            "description": "Du är nu ansluten till din enhet, välj nedan vad du vill göra",
            "error-while-booting": "Ett fel uppstod under uppstart, sannolikt på grund av en ogiltig konfiguration.",
            "show-details": "Klicka här för att se detaljer"
        },
        "file-browser": {
            "title": "Filhanterare",
            "loading": "Laddar...",
            "downloading": "Laddar ner...",
            "uploading": "Laddar upp",
            "config-missing": "Konfigurationsfilen \"{{configFilename}}\" saknas, vill du skapa den?",
            "create-config": "Skapa konfiguration",
            "create-new-config": "Skapa en ny konfiguration",
            "upload": "Ladda upp",
            "active-config": "Aktiv konfiguration",
            "select-config": "Välj konfiguration",
            "select-as-config": "Välj som konfiguration",
            "edit": "Redigera",
            "delete": "Ta bort",
            "download": "Ladda ner"
        },
        "terminal": {
            "title": "Terminal",
            "restarting": "Startar om enheten...",
            "restart": "Starta om",
            "restart-description": "Gör en hård omstart av enheten genom att ställa in DTR/RTS",
            "reset": "Starta om",
            "reset-description": "Startar om enheten med ett 0x18 kommando",
            "unlock": "Lås upp",
            "unlock-description": "Låser upp enheten med $X",
            "status": "Status",
            "status-description": "Hämta enhetens status",
            "startup": "Startmeddelanden",
            "startup-description": "Hämta uppstartsmeddelanden från enheten",
            "version": "Version",
            "version-description": "Hämta enhetens programvaruversion"
        }
    },
    "panel": {
        "navigation": {
            "home": "Hem",
            "install": "Installera",
            "terminal": "Terminal",
            "file-browser": "Filhanterare",
            "wifi": "WiFi",
            "calibrate": "Kalibrera",
            "restart": "Starta om",
            "disconnect": "Stäng anslutning"
        },
        "firmware": {
            "install": "Installera",
            "install-description": "Välj vilken version du vill installera på din enhet.",
            "loading": "Laddar...",
            "install-custom-image": "Installera ett eget paket...",
            "show-prereleases": "Visa förhandsversioner",
            "fetching": "Hämtar"
        },
        "progress": {
            "title": "Installerar",
            "downloading": "Laddar ner paket...",
            "validating": "Validerar paket...",
            "flashing": "Installerar paket till enheten..."
        },
        "bootloader": {
            "waiting": "Väntar på att enheten ska starta bootloadern...",
            "not-active": "Bootloader är inte aktiv - Försök att hålla ner BOOT-knappen"
        },
        "unsupported": {
            "title": "Webbläsaren stöds inte",
            "description": "Din nuvarande webbläsare stöds inte, använd Chrome, Edge eller Opera istället"
        }
    },
    "component": {
        "log": {
            "show-details": "Visa detaljer",
            "hide-details": "Dölj detaljer"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "Ett fel uppstod vid återanslutning, vänligen starta om enheten",
            "uploading": "Laddar upp",
            "downloading": "Laddar ner",
            "downloading-description": "Laddar ner paket...",
            "restarting": "Startar om",
            "restarting-description": "Väntar på omstart av enheten...",
            "uploading-files": "Laddar upp filer",
            "uploading-files-description": "Laddar upp filer...",
            "done": "Klar",
            "done-description": "Enheten har installerats och är redo att användas.",
            "continue": "Fortsätt",
            "error": "Ett fel har uppstått!",
            "close": "Stäng",
            "confirm-installation": "Bekräfta installation",
            "confirm-installation-description": "Detta kommer att installera \"{{release}}\" till enheten och kommer {{not}} att radera den.",
            "not": "inte",
            "installation-speed": "Installationshastighet",
            "installation-speed-help": "Vissa enheter måste installeras med lägre hastighet. Om du får problem kan du försöka minska hastigheten.",
            "install": "Installera",
            "cancel": "Avbryt",
            "select-image": "Installera eget paket",
            "select-image-description1": "Om du har laddat ner ett paket manuellt kan du installera den här.",
            "select-image-description2": "Välj den firmware.bin-fil du vill installera.",
            "select-image-button": "Välj paket"
        },
        "lost-connection": {
            "description": "Tappade anslutningen till enheten",
            "close": "Stäng"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Uppgradera FluidNC på din hårdvara",
            "install-description": "Din enhet verkar inte ha FluidNC installerat, vill du installera det?",
            "upgrade-button": "Uppgradera",
            "install-button": "Installera"
        },
        "terminal": {
            "open-terminal": "Öppna terminal",
            "open-terminal-description": "Öppna en seriell terminal till din enhet"
        },
        "file-browser": {
            "open-browser": "Filhanterare",
            "open-browser-description": "Hantera filer på din enhet"
        },
        "calibrate": {
            "calibrate": "Kalibrera",
            "calibrate-description": "Kalibrera inställningarna på din enhet"
        },
        "wifi": {
            "configure": "Konfigurera WiFi",
            "configure-description": "Hantera WiFi-inställningar på hårdvaran",
            "connected-to": "Ansluten till",
            "access-point": "Accesspunkt"
        },
        "donate": {
            "description": "Vänligen överväg en donation till FluidNC-projektet via länkarna nedan för att hålla igång det."
        }
    }
}
