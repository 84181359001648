{
    "header": {
        "documentation": "Documentação",
        "translate": "Add translations"
    },
    "footer": {
        "documentation": "Documentação"
    },
    "page": {
        "connection": {
            "title": "Instalador Web FluidNC",
            "description": "Essa ferramenta irá facilitar a instalação ou atualização do FluidNC na sua controladora. Conecte a sua controladora e pressione Conectar para continuar.",
            "connect": "Conectar",
            "connecting": "Conectando",
            "establishing-connection": "Estabelecendo conexão com a controladora"
        },
        "home": {
            "description": "Agora você está conectado a um dispositivo, por favor selecione uma ação abaixo",
            "error-while-booting": "Ocorreu um erro durante a inicialização, provavelmente devido a uma configuração inválida.",
            "show-details": "Clique aqui para visualizar detalhes"
        },
        "file-browser": {
            "title": "File browser",
            "loading": "Loading...",
            "downloading": "Downloading...",
            "uploading": "Uploading",
            "config-missing": "The configuration file \"{{configFilename}}\" is missing, do you want to create it?",
            "create-config": "Create config",
            "create-new-config": "Create new config",
            "upload": "Upload",
            "active-config": "Active config",
            "select-config": "Select config",
            "select-as-config": "Select as config",
            "edit": "Edit",
            "delete": "Delete",
            "download": "Download"
        },
        "terminal": {
            "title": "Terminal",
            "restarting": "Restarting controller...",
            "restart": "Restart",
            "restart-description": "Does a hard reset on the controller by setting DTR/RTS",
            "reset": "Reset",
            "reset-description": "Resets the controller with a 0x18 command",
            "unlock": "Unlock",
            "unlock-description": "Unlocks the controller with $X",
            "status": "Status",
            "status-description": "Get the controller status",
            "startup": "Startup messages",
            "startup-description": "Get startup log messages",
            "version": "Version",
            "version-description": "Get the firmware version"
        }
    },
    "panel": {
        "navigation": {
            "home": "Início",
            "install": "Instalar",
            "terminal": "Terminal",
            "file-browser": "Navegador de arquivos",
            "wifi": "Wi-Fi",
            "calibrate": "Calibrar",
            "restart": "Reiniciar",
            "disconnect": "Desconectar"
        },
        "firmware": {
            "install": "Install",
            "install-description": "Select which firmware you want to install on your controller.",
            "loading": "Loading...",
            "install-custom-image": "Install a custom image...",
            "show-prereleases": "Show pre-releases",
            "fetching": "Fetching"
        },
        "progress": {
            "title": "Installing",
            "downloading": "Downloading package...",
            "validating": "Validating images...",
            "flashing": "Installing package to device..."
        },
        "bootloader": {
            "waiting": "Waiting for controller to enter bootloader...",
            "not-active": "Bootloader not active - Try holding down the BOOT switch"
        },
        "unsupported": {
            "title": "Browser not supported",
            "description": "Your current web browser is not supported, please use Chrome, Edge or Opera instead"
        }
    },
    "component": {
        "log": {
            "show-details": "Exibir detalhes",
            "hide-details": "Ocultar detalhes"
        }
    },
    "modal": {
        "installer": {
            "error-reconnecting": "An error occured while reconnecting, please reboot the controller",
            "uploading": "Uploading",
            "downloading": "Downloading",
            "downloading-description": "Downloading package...",
            "restarting": "Restarting",
            "restarting-description": "Waiting for controller restart...",
            "uploading-files": "Uploading files",
            "uploading-files-description": "Uploading files...",
            "done": "Done",
            "done-description": "The controller has been successfully installed and is ready to be used.",
            "continue": "Continue",
            "error": "Error!",
            "close": "Close",
            "confirm-installation": "Confirm installation",
            "confirm-installation-description": "This will install the firmware \"{{release}}\" to the controller. It will {{not}} erase the controller.",
            "not": "not",
            "installation-speed": "Installation speed",
            "installation-speed-help": "Some controllers need to be installed at a lower speed. If you are experiencing problems you can try and decrease the speed.",
            "install": "Install",
            "cancel": "Cancel",
            "select-image": "Select image",
            "select-image-description1": "If you have downloaded a package manually you can flash the image from here.",
            "select-image-description2": "Select the firmware.bin file to flash the controller with.",
            "select-image-button": "Select firmware image"
        },
        "lost-connection": {
            "description": "Lost the connection to the controller",
            "close": "Close"
        }
    },
    "card": {
        "install": {
            "upgrade-description": "Atualizar FluidNC na sua controladora",
            "install-description": "A controladora parece não ter o FluidNC instalado, deseja instalá-lo?",
            "upgrade-button": "Atualizar o FluidNC",
            "install-button": "Instalar o FluidNC"
        },
        "terminal": {
            "open-terminal": "Abrir terminal",
            "open-terminal-description": "Abrir um terminal para sua controladora"
        },
        "file-browser": {
            "open-browser": "Navegador de arquivos",
            "open-browser-description": "Gerenciar arquivos da controladora"
        },
        "calibrate": {
            "calibrate": "Calibrar",
            "calibrate-description": "Calibrar configurações da sua controladora"
        },
        "wifi": {
            "configure": "Configurar Wi-Fi",
            "configure-description": "Gerenciar configurações de Wi-Fi da controladora",
            "connected-to": "Conectado a",
            "access-point": "Ponto de Acesso"
        },
        "donate": {
            "description": "Please consider a donation to the FluidNC project via the links below to keep it going."
        }
    }
}
